<style>
.title     { text-align:center; color:#aa6; background-color:#444; font-size:22px; margin:-36px 80px 15px 80px; border-left:2px solid #aa6; border-right:2px solid #aa6; padding-bottom:1px; }
.panel     { padding:20px; background-color:#333; width:470px; margin:40px auto 20px auto; position:relative; }
.label     { color:#999; font-size:15px; width:136px; display:inline-block; }
.stat      { color:#bbb; font-size:22px; width:150px; display:inline-block; text-align:right; }
.info      { color:#999; font-size:15px; display:inline-block; padding-left:3px; }
.info.icon { width:18px; height:18px; padding-left:1px; margin-top:-5px; }
#supplyTooltip { padding:5px 10px; width:196px; background-color:#555; position:absolute; top:180px; left:225px; display:none; }
#supplyTooltip .label { color:#ddd; font-size:12px; width:80px; }
#supplyTooltip .stat { color:#ddd; font-size:12px; width:90px; }</style>

<div class="panel">
  <div class="title">Rito Stats</div>
  <div class="label">Last Block</div>         <div class="stat">{formattedInt(height)}</div> <div class="info">{block_age}</div><br>
  <div class="label">Net Hash</div>           <div class="stat">{formattedInt(networkhashps)}</div> <div class="info">Gh/s</div><br>
  <div class="label">Difficulty</div>         <div class="stat">{formattedInt(difficulty)}</div><br>
  <div class="label">Last Reward</div>        <div class="stat">{formattedInt(reward)}</div><br>
  <div class="label">Circ. Supply</div>       <div class="stat">{formattedInt(supply)}</div> <a href="javascript://" use:tooltip={'supplyTooltip'}><img class="info icon" src="fi-info.svg" alt=""></a><br>
  <div class="label">Last Price</div>         <div class="stat">&nbsp;</div><br>
  {#each last_rito as last}
  <div class="label" style="padding-left:15px">{last.name}</div>  <div class="stat">{last.price.toFixed(8)}</div> <div class="info">vol {last.volume.toFixed(0)}</div><br>
  {/each}
  <div id="supplyTooltip">
    <div class="label">Rewards</div>  <div class="stat">+ {formattedInt(total_rewards)}</div><br>
    <div class="label">Dev Fund</div> <div class="stat">+ {formattedInt(total_dev_funds)}</div><br>
    <div class="label">Burned</div>   <div class="stat">- {formattedInt(total_burned)}</div><br>
  </div>
</div>

<script>
  import { onMount } from 'svelte'
  import moment from 'moment'

  export let socket

	let height                    = ''
  let time                      = ''
  let block_age                 = ''
	let networkhashps             = ''
	let difficulty                = ''
	let reward                    = ''
  let dev_fund                  = ''
  let tx_fee                    = ''
  let total_rewards             = ''
  let total_dev_funds           = ''
  let total_tx_fees             = ''
  let total_burned              = ''
  let supply                    = ''
  let last_btc                  = ''
  let last_rito                 = []

	function formattedNum(num) {
    var parts = num.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return parts.join(".")
  }

  function formattedInt(num) {
    return formattedNum(Math.round(num))
  }

  function tooltip(node, id) {
    node.addEventListener('mouseenter', () => document.getElementById(id).style.display = 'block' )
		node.addEventListener('mouseleave', () => document.getElementById(id).style.display = 'none' )
	}

  onMount(async () => {
    setInterval(function(){
      block_age = moment(Number(time) * 1000).fromNow()
    }, 5000)
	})

  socket.on('connect', messages => {
    socket.emit('request', 'currentBlockInfo')
  })

  socket.on('currentBlockInfo', info => {
    height           = info.height
    time             = info.time
    block_age        = moment(info.time * 1000).fromNow()
    networkhashps    = Math.round(info.networkhashps / 1000000000)
    difficulty       = Math.round(info.difficulty)
    reward           = info.reward
    dev_fund         = info.dev_fund
    tx_fee           = info.tx_fee
    total_rewards    = Math.round(info.total_rewards / 100000000)
    total_dev_funds  = Math.round(info.total_dev_funds / 100000000)
    total_tx_fees    = Math.round(info.total_tx_fees / 100000000)
    total_burned     = Math.round(info.total_burned / 100000000)
    supply           = total_rewards + total_dev_funds - total_burned
  })

  socket.on('currentPriceInfo', info => {
    if (Object.keys(info).length > 0) {
      last_btc                   = info.btc.toFixed(2)
      last_rito                  = info.rito
    }
  })

  socket.on('reload', () => {
    window.location.reload()
  })
</script>
