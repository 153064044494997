<script>
  import { onMount } from 'svelte'

  export let socket

  let margin = {top: 20, right: 85, bottom: 60, left: 110}
  let width = 500 - margin.left - margin.right
  let height = 270 - margin.top - margin.bottom
  let data = []
  let svg

  onMount(async () => {
	})

  function buildChart() {
    let minDate = new Date(data[0].date)
    let maxDate = new Date(data[data.length-1].date)

    let x = d3.time.scale()
                   .domain([minDate, maxDate])
                   .range([0, width])
    let y1 = d3.scale.linear()
                    .domain([0, d3.max(data, (d) => { return d.total_rewards; })])
                    .range([height, 0])
    let y2 = d3.scale.linear()
                    .domain([0, d3.max(data, (d) => { return d.avg_difficulty; })])
                    .range([height, 0])

    let xAxis = d3.svg.axis()
                      .scale(x)
                      .orient("bottom")
                      .tickFormat(d3.time.format("%Y-%m"))
    let yAxis1 = d3.svg.axis()
                      .scale(y1)
                      .orient("left")
                      .ticks(5)
    let yAxis2 = d3.svg.axis()
                      .scale(y2)
                      .orient("right")
                      .ticks(5)

    d3.select(".chart").select("svg").remove()

    svg = d3.select(".chart").append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    svg.append("g")
        .attr("class", "x axis")
        .attr("fill", "#999")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", "-.55em")
        .attr("transform", "rotate(-90)" )

    svg.append("g")
        .attr("class", "y axis")
        .attr("fill", "#999")
        .style("fill", "#838c50")
        .call(yAxis1)
      .append("text")
        .attr("y", -80)
        .attr("x", -144)
        .attr("transform", "rotate(-90)")
        .text("Rewards per Day")

    svg.selectAll(".bar")
        .data(data).enter()
      .append("rect")
        .style("fill", "#636c30")
        .attr("class", "bar")
        .attr("x", (d) => { return x(new Date(d.date)); })
        .attr("width", width / data.length)
        .attr("y", (d) => { return y1(d.total_rewards); })
        .attr("height", (d) => { return height - y1(d.total_rewards); })

    var valueline2 = d3.svg.line()
    	.x(function (d) { return x(new Date(d.date)); })
    	.y(function (d) { return y2(d.avg_difficulty); });

    svg.append("g")
        .attr("class", "y axis")
        .attr("fill", "#999")
        .attr("transform", "translate(" + width + " ,0)")
        .style("fill", "#dcaf67")
        .call(yAxis2)
      .append("text")
        .attr("y", -56)
        .attr("x", 50)
        .attr("transform", "rotate(90)")
        .text("Average Difficulty")

    svg.append("path")
        .data([data])
        .style("stroke", "#c89b69")
        .style("fill", "none")
        .style("stroke-width", "2px")
        .attr("d", valueline2)
  }

  socket.on('connect', () => {
    setTimeout(function(){
      socket.emit('request', 'fullDailyInfo')
    }.bind(this), 500)
  })

  socket.on('fullDailyInfo', daily_info => {
    data.length = 0
    for (let date in daily_info) {
      let day_info = daily_info[date]
      let obj = {'date':date, 'avg_difficulty':Math.round(day_info.total_difficulty/day_info.blocks), 'total_rewards':day_info.total_rewards}
      data.push(obj)
    }
    buildChart()
  })

  socket.on('newDailyInfo', daily_info => {
    let date = Object.keys(daily_info)[0]
    let day_info = daily_info[date]
    let obj = {'date':date, 'avg_difficulty':Math.round(day_info.total_difficulty/day_info.blocks), 'total_rewards':day_info.total_rewards}
    if (data[data.length-1].date == date) {
      data[data.length-1] = obj
    } else {
      data.push(obj)
    }
    buildChart()
  })
</script>

<style>
 .chart { width:500px; margin:20px auto 20px auto; font:12px sans-serif; }
:global(.axis path),
:global(.axis line) {
  fill: none;
  stroke: #888;
  shape-rendering: crispEdges;
}</style>

<div class="chart"></div>
